import React from 'react'

import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'

import Layout from '../layouts/basiclayout'
import './research.css'


// import ZheZhangAvatar from '../../images/research/zhezhang.jpg'
// import PKUlogo from '../../images/research/Education/PKU.png'
// import TJUlogo from '../../images/research/Education/TJU.png'

// import GeoMVSNet from '../../images/research/Publications/GeoMVSNet.png'
// import N2MVSNet from '../../images/research/Publications/N2MVSNet.png'
// import ContextMVS from '../../images/research/Publications/ContextMVS.png'

// import SAP from '../../images/research/Intern/SAP.png'
// import Tencent from '../../images/research/Intern/Tencent.png'
// import TencentAILab from '../../images/research/Intern/Tencent-AI-Lab.png'


class Research extends React.Component {

    render() {
        // const siteTitle = get(this, 'props.data.site.siteMetadata.title')
        const thisTitle = "Zhe Zhang @PKU"
        
        return (
            <Layout location={this.props.location}>
                <Helmet title={`${thisTitle}`} />

                <div className='ResearchMainContainer'>
                    {/* @doubleZ Hero */}
                    <div className='ResearchHeroContainer'>
                      <h1 className='hero-title' style={{
                        "gridArea": "title"}}>Zhe Zhang</h1>
                      <div className='HeroContent' style={{
                        "gridArea": "content"}}>
                        <p className='hero-info'>
                        {/* Zhe Zhang (张喆) is a master student in the School of Electronic and Computer Engineering (<a href='https://www.ece.pku.edu.cn/' target='_blank'>ECE</a>), Peking University (<a href='https://www.pku.edu.cn' target='_blank'>PKU</a>). He is supervised by Prof. <a href='https://www.ece.pku.edu.cn/info/1073/2562.htm' target='_blank'>Ronggang Wang</a> at the National Engineering Research Center of Visual Technology (<a href='https://idm.pku.edu.cn/' target='_blank'>NERC V<sup>2</sup>T</a>). His research interests include computer vision and computer graphics, especially in multi-view stereo (MVS), 3D reconstruction and depth estimation. */}

                        Zhe Zhang (张喆) is a Senior Algorithm Engineer at the <a href="https://tongyi.aliyun.com/" target="_blank">Insitute for Intelligent Computing</a> (previously known as <a href='https://damo.alibaba.com/' target='_blank'>DAMO Academy</a>) / Alibaba Group. Prior to joining Alibaba, he obtained the Master's degree in Science in the School of Electronic and Computer Engineering (<a href='https://www.ece.pku.edu.cn/' target='_blank'>ECE</a>), Peking University (<a href='https://www.pku.edu.cn' target='_blank'>PKU</a>). He was supervised by Prof. Ronggang Wang at the National Engineering Research Center of Visual Technology (<a href='https://idm.pku.edu.cn/' target='_blank'>NERC V<sup>2</sup>T</a>). His research interests include computer vision and computer graphics, especially in AIGC, multi-view stereo (MVS), 3D reconstruction and depth estimation.
                        </p>
                        <div className='hero-socialmedia'>
                          <a href='mailto:doublez@stu.pku.edu.cn'>Email</a> / <a href='https://www.doublez.site/'>Homepage</a> / <a href='https://github.com/doubleZ0108' target='_blank'>Github</a> / <a href='https://www.linkedin.com/in/doubleZ0108' target='_blank'>LinkedIn</a> / <a href='https://unsplash.com/@doublez0108' target='_blank'>Unsplash</a> / <a href='https://www.zhihu.com/people/doubleZ0108/columns' target='_blank'>Zhihu Blog</a> / <a href='https://assets.ctfassets.net/odsfb7g0v9wp/wae2wMsoTCdZowoqAWs9E/99d0186fad64933079d4a52c6f21fe4c/ZheZhang-PKU-CV-en.pdf' target='_blank'>CV</a>
                          {/* <a href='https://twitter.com/doubleZ0108' target='_blank'>Twitter</a> */}
                        </div>
                      </div>
                      <div className='HeroImage' style={{
                        "gridArea": "img"}}>
                        {/* <img src='https://images.ctfassets.net/odsfb7g0v9wp/0v1Tc0UKK1ONgELrOlPuR/07c38dd982cb2d452d69b42f37ce2a28/zhezhang-half.jpg' draggable="false" /> */}
                        <img src='https://images.ctfassets.net/odsfb7g0v9wp/53QBDpRWamZhWYrqv5ZAUk/194f7bdf873966911dedc59f0d866696/hero-boya.jpeg' draggable="false" />
                        </div>
                    </div>

                    {/* @doubleZ Education */}
                    <div className='ResearchCaption'><h2>Education</h2></div>
                    <div className='ResearchEducationContainer'>
                      <div className='School'>
                        <div className='school-logo'><img src='https://images.ctfassets.net/odsfb7g0v9wp/62Wa5XRHLOebItc4u7yx5Q/8b6fd84bc957a2b0791323345b37b72c/pku.png' draggable="false" /></div>
                        <div className='school-info'>
                          <h3><strong>Peking University</strong></h3>
                          <div className='school-time'>
                            <div class="left">M.S. in Computer Science</div>
                            <div class="right">Aug. 2021 - Jul. 2024</div>
                          </div>
                          <p className='school-gpa'>GPA: 3.97/4.00 (99.3/100.0)</p>
                        </div>
                      </div>

                      <div className='School'>
                        <div className='school-logo'><img src='https://images.ctfassets.net/odsfb7g0v9wp/7t1gKi7y3geLEo3g4aUCIR/6e94cddae2a4cfae3df7e49016976be9/tongji.png' draggable="false" /></div>
                        <div className='school-info'>
                          <h3><strong>Tongji University</strong></h3>

                          <div className='school-time'>
                            <div class="left">B.Eng in Software Engineering</div>
                            <div class="right">Sep. 2017 - Jul. 2021</div>
                          </div>
                          <p className='school-gpa'>GPA: 4.79/5.00 (Overall Ranking 1st | Grade Ranking Top 2.8%)</p>

                          <div className='school-time'>
                            <div class="left">Minor in Artificial Intelligence</div>
                            <div class="right">Sep. 2019 - Jue. 2021</div>
                          </div>
                          <p className='school-gpa'>GPA: 5.00/5.00 (The only Full GPA)</p>
                        </div>
                      </div>
                    </div>

                    {/* @doubleZ Publicatioin */}
                    <div className='ResearchCaption'><h2>Publications</h2></div>
                    <div className='ResearchPublicationContainer'>
                      <div className='Publication'>
                        <div className='publication-arch-fig'><img src='https://images.ctfassets.net/odsfb7g0v9wp/4P9kYNSWaDlWQawHOxH0c/cee7cb84f33db49513675e2f44907409/GeoMVSNet.png' draggable="false" /></div>
                        <div className='publication-info'>
                          <h3><strong>GeoMVSNet: Learning Multi-View Stereo with Geometry Perception</strong></h3>
                          <p><strong>Zhe Zhang</strong>, Rui Peng, Yuxi Hu, Ronggang Wang*</p>
                          <p>IEEE Conference on Computer Vision and Pattern Recognition (CVPR), 2023</p>
                          <div className='publication-links'>
                            <a href='https://openaccess.thecvf.com//content/CVPR2023/papers/Zhang_GeoMVSNet_Learning_Multi-View_Stereo_With_Geometry_Perception_CVPR_2023_paper.pdf' target='_blank'>Paper</a> / <a href='https://openaccess.thecvf.com/content/CVPR2023/supplemental/Zhang_GeoMVSNet_Learning_Multi-View_CVPR_2023_supplemental.pdf' target='_blank'>Supp.</a> / <a href='https://doublez-demo.oss-cn-shanghai.aliyuncs.com/mvs-3d-reconstruction.mp4' target='_blank'>Demo</a> / <a href='https://www.youtube.com/watch?v=XqLDgJAZAKc' target='_blank'>Video</a> / <a href='https://github.com/doubleZ0108/GeoMVSNet' target='_blank'>Code</a>
                          </div>
                        </div>
                      </div>

                      <div className='Publication'>
                        <div className='publication-arch-fig'><img src='https://images.ctfassets.net/odsfb7g0v9wp/2xCFnVcVKZLAddjBojgJQU/d81bea78fb8f9b0747ca6f09d7ccd1da/N2MVSNet.png' draggable="false" /></div>
                        <div className='publication-info'>
                          <h3><strong>N2MVSNet: Non-Local Neighbors Aware Multi-View Stereo Network</strong></h3>
                          <p><strong>Zhe Zhang</strong>, Huachen Gao, Yuxi Hu, Ronggang Wang*</p>
                          <p>IEEE International Conference on Acoustics, Speech and Signal Processing (ICASSP), 2023</p>
                          <div className='publication-links'>
                            <a href='https://ieeexplore.ieee.org/document/10095299' target='_blank'>Paper</a>
                          </div>
                        </div>
                      </div>

                      <div className='Publication'>
                        <div className='publication-arch-fig'><img src='https://images.ctfassets.net/odsfb7g0v9wp/4ppyW5SAEdA32lN0cYCErU/e17a560fe12f234b9412de9aff8c4cdb/Bi-ClueMVSNet.png' draggable="false" /></div>
                        <div className='publication-info'>
                          <h3><strong>Bi-ClueMVSNet: Learning Bidirectional Occlusion Clues for Multi-View Stereo</strong></h3>
                          <p><strong>Zhe Zhang</strong>, Yuxi Hu, Huachen Gao, Ronggang Wang*</p>
                          <p>International Joint Conference on Neural Networks (IJCNN), 2023</p>
                          <div className='publication-links'>
                            <a href='https://ieeexplore.ieee.org/document/10191325'  target='_blank'>Paper</a>
                          </div>
                        </div>
                      </div>

                      <div className='Publication'>
                        <div className='publication-arch-fig'><img src='https://images.ctfassets.net/odsfb7g0v9wp/4x7FmXgSp9nfzAi2FhY4eJ/05a797c5384205f982b1905ae69ec797/CL-MVSNet.png' draggable="false" /></div>
                        <div className='publication-info'>
                          <h3><strong>CL-MVSNet: Unsupervised Multi-view Stereo with Dual-level Contrastive Learning</strong></h3>
                          <p>Kaiqiang Xiong, Rui Peng, <strong>Zhe Zhang</strong>, Tianxing Feng, Jianbo Jiao, Feng Gao, Ronggang Wang*</p>
                          <p>International Conference on Computer Vision (ICCV), 2023</p>
                          <div className='publication-links'>
                            <a href='https://openaccess.thecvf.com/content/ICCV2023/papers/Xiong_CL-MVSNet_Unsupervised_Multi-View_Stereo_with_Dual-Level_Contrastive_Learning_ICCV_2023_paper.pdf' target='_blank'>Paper</a> / <a href='https://github.com/KaiqiangXiong/CL-MVSNet' target='_blank'>Code</a>
                          </div>
                        </div>
                      </div>

                      <div className='Publication'>
                        <div className='publication-arch-fig'><img src='https://images.ctfassets.net/odsfb7g0v9wp/40XlGxQ25NYcSFmWjv9Mww/90e7d7432cfd638cf93af4de7faa16b1/Points-to-3D.png' draggable="false" /></div>
                        <div className='publication-info'>
                          <h3><strong>Points-to-3D: Bridging the Gap between Sparse Points and Shape-Controllable Text-to-3D Generation</strong></h3>
                          <p>Chaohui Yu, Qiang Zhou, Jingliang Li, <strong>Zhe Zhang</strong>, Zhibin Wang, Fan Wang*</p>
                          <p>ACM International Conference on Multimedia (ACM MM), 2023</p>
                          <div className='publication-links'>
                            <a href='https://arxiv.org/abs/2307.13908' target='_blank'>Paper</a>
                          </div>
                        </div>
                      </div>

                      <div className='Publication'>
                        <div className='publication-arch-fig'><img src='https://images.ctfassets.net/odsfb7g0v9wp/7s7ZouIVDEMCeT0yemnipL/aeb51493081eae07455de6cb822b5880/ContextMVS.png' draggable="false" /></div>
                        <div className='publication-info'>
                          <h3><strong>Context-Guided Multi-view Stereo with Depth Back-Projection</strong></h3>
                          <p>Tianxing Feng, <strong>Zhe Zhang</strong>, Kaiqiang Xiong, Ronggang Wang*</p>
                          <p>International Conference on Multimedia Modeling (MMM), 2023</p>
                          <div className='publication-links'>
                            <a href='https://link.springer.com/chapter/10.1007/978-3-031-27818-1_8' target='_blank'>Paper</a>
                          </div>
                        </div>
                      </div>

                      <div className='Publication'>
                        <div className='publication-arch-fig'><img src='https://images.ctfassets.net/odsfb7g0v9wp/6YmaZrFJ6YR3mA0Z4M6d6K/ac41eccd541b228886ccb7f30df81454/FDC-NeRF.jpg' draggable="false" /></div>
                        <div className='publication-info'>
                          <h3><strong>FDC-NeRF: Learning Pose-free Neural Radiance Fields with Flow-Depth Consistency</strong></h3>
                          <p>Huachen Gao, Shihe Shen,<strong>Zhe Zhang</strong>, Kaiqiang Xiong, Rui Peng, Zhirui Gao, Qi Wang, Yugui Xie, Ronggang Wang*</p>
                          <p>IEEE International Conference on Acoustics, Speech and Signal Processing (ICASSP), 2024</p>
                          <div className='publication-links'>
                            <a href='https://ieeexplore.ieee.org/abstract/document/10446550' target='_blank'>Paper</a>
                          </div>
                        </div>
                      </div>
                      
                    </div>

                    {/* <div className='ResearchCaption'><h2>Projects</h2></div>
                    <div className='ResearchProjectContainer'>
                    
                    </div> */}

                    {/* @doubleZ Intern */}
                    <div className='ResearchCaption'><h2>Intern</h2></div>
                    <div className='ResearchInternContainer'>
                      <div className='Intern'>
                        <div className='intern-header'>
                            <div className='intern-logo'>
                              <img id='tencent-logo' src='https://images.ctfassets.net/odsfb7g0v9wp/35naAl7ABGazXGYBJyTQ3R/4f126e19be09ae2e6897a48505f9dcac/tencent_logo.png' draggable="false" /> <img id='tencent-ai-lab-logo' src='https://images.ctfassets.net/odsfb7g0v9wp/2GWzS6tBDWupaE7dLI50zM/eca2da7044ecb36c2c80ded50fab5191/Tencent-AI-Lab-logo.png' draggable="false" />
                            </div>
                            {/* <p className='intern-time'>11 months</p> */}
                        </div>
                        
                        <div className='intern-info'>
                          <ul>
                            <li>TEG / <a href="https://ai.tencent.com/ailab/zh/index" target='_blank'>AI Lab</a> / Digital Human Team intern. <a className='demo' href='https://doublez-demo.oss-cn-shanghai.aliyuncs.com/ZheZhang-3DRealisticDigitalHuman.mp4' target='_blank'>Demo</a></li>
                            <li>Maintain the <strong>4D Scanning Realistic Digital Human</strong> pipeline and improve the face reconstruction and rendering algorithm.</li>
                          </ul>
                        </div>
                      </div>

                      <div className='Intern'>
                        <div className='intern-header'>
                            <div className='intern-logo'>
                              <img id='alibaba-logo' src='https://images.ctfassets.net/odsfb7g0v9wp/6lQhppmcXdMbUt6FE2SSr6/a17a4e790ddc3d26bc1e51854a193067/alibaba.png' draggable="false" /> <img id='damo-logo' src='https://images.ctfassets.net/odsfb7g0v9wp/1u42BfraBrUiDh8cmDoiIl/6a3fa53cff1bb77a731106f74529815a/damo.png' draggable="false" />
                            </div>
                            {/* <p className='intern-time'>3 months</p> */}
                        </div>
                        
                        <div className='intern-info'>
                          <ul>
                            <li>Alibaba Group / <a href="https://damo.alibaba.com/?lang=zh" target='_blank'>DAMO Academy</a> / Analytical Insight of Earth (<a href="https://engine-aiearth.aliyun.com/" target='_blank'>AI EARTH</a>) intern.</li>
                          </ul>
                        </div>
                      </div>

                      <div className='Intern'>
                        <div className='intern-header'>
                            <div className='intern-logo'>
                              <img id='baidu-logo' src='https://images.ctfassets.net/odsfb7g0v9wp/7lXW0kGbpGW7kB9AkfTEl9/57379d67c96e42d674d335c2828954c0/baidu.png' draggable="false" /> <img id='apollo-logo' src='https://images.ctfassets.net/odsfb7g0v9wp/2ctfQf6zVutorHDlz1mlsS/fb899e9c49a5a7c5ab847654af153a34/apollo.png' draggable="false" />
                            </div>
                            {/* <p className='intern-time'>2 months</p> */}
                        </div>
                        
                        <div className='intern-info'>
                          <ul>
                            <li>IDG / Apollo Navigation Pilot Main Line R & D Group research intern.</li>
                          </ul>
                        </div>
                      </div>

                      <div className='Intern'>
                        <div className='intern-header'>
                            <div className='intern-logo'>
                              <img id='dji-logo' src='https://images.ctfassets.net/odsfb7g0v9wp/1uke6PKYHBB85ByzFIzLC6/6e4cd583dac3538ee0ad5af002aa80fc/dji.png' draggable="false" /> <img id='dji-auto-logo' src='' draggable="false" />
                            </div>
                            {/* <p className='intern-time'>2 months</p> */}
                        </div>
                        
                        <div className='intern-info'>
                          <ul>
                            <li><a href='https://www.zyt.com/zh' target='_blank'>Vehicle Business Department</a> / Autonomous Driving R & D Department / Sensing Department / Local Map Group / 3D Reconstruction intern.</li>
                          </ul>
                        </div>
                      </div>

                      <div className='Intern'>
                        <div className='intern-header'>
                            <div className='intern-logo'>
                              <img id='sap-logo' src='https://images.ctfassets.net/odsfb7g0v9wp/XRXdnwBWDHs6mFfKfJVk8/88c37e1113bb65a33c1b7e4bd16bd995/sap.png' draggable="false" />
                            </div>
                            {/* <p className='intern-time'>1 year and 6 months</p> */}
                          
                        </div>
                        
                        <div className='intern-info'>
                          <ul>
                            <li>VT SH Batch 2019 Master2 Program.</li>
                            <li>S/4 HANA Cloud department, technical consultant intern.</li>
                            <li>Vice Chairman of Tongji-SAP Club.</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    {/* @doubleZ Awards & Honors */}
                    <div className='ResearchCaption'><h2>Awards & Honors</h2></div>
                    <div className='ResearchAwardContainer'>
                      <ol>
                        <li><div>China National Scholarship.<span>2019-2020</span></div></li>
                        <li><div>Outstanding Master Graduate of Beijing Province.<span>Jun. 2024</span></div></li>
                        <li><div>Outstanding Graduate of Shanghai Province.<span>Jun. 2021</span></div></li>
                        <li><div>Pursuit Preeminent Student Award of Tongji Univ (Highest-level award).<span>May. 2021</span></div></li>
                        <li><div>Model Student Award & PINGAN Scholarship of Peking Univ.<span>2022-2023</span></div></li>
                        <li><div>Social Work Award of Peking Univ.<span>2021-2022</span></div></li>
                        <li><div>Excellent Student Model Award of Tongji Univ.<span>2019-2020</span></div></li>
                        <li><div>Excellent Leadership Award of Tongji Univ.<span>2017-2018</span></div></li>
                        <li><div>First-class Scholarship & Social Activity Scholarship of Tongji Univ.<span>2017-2018</span></div></li>
                        {/* <li><div>Social Activity Scholarship of Tongji Univ.<span>2017-2021</span></div></li> */}
                        <li><div>Outstanding Volunteer of Tongji Univ.<span>2019-2020</span></div></li>
                        <li><div>Finalist Award of MCM/ICM 2020.<span>Apr. 2020</span></div></li>
                        <li><div>Mathematical Contest in Modeling of China, 2nd. Shanghai competition area, 1st.<span>Nov. 2019</span></div></li>
                      </ol>
                    </div>

                    {/* @doubleZ Others */}
                    <div className='ResearchCaption'><h2>Others</h2></div>
                    <div className='ResearchOthersContainer'>

                      <div className='OtherContainer'>
                        <h3 className='other-title'>Service</h3>
                        <ul>
                          <li>Conference Reviewer: ICASSP, ACCV, IJCNN</li>
                          <li>Journal Reviewer: IEEE RA-L</li>
                        </ul>
                      </div>

                      <div className='OtherContainer'>
                        <h3 className='other-title'>Student Works</h3>
                        <ul>
                          <li>Chairman of Tongji Univ. Student Union.</li>
                          <li>Outstanding Student of the Leadership Training School of Peking Univ.</li>
                        </ul>
                      </div>

                      <div className='OtherContainer'>
                        <h3 className='other-title'>Volunteer</h3>
                        <ul>
                          <li>Outstanding volunteer of “Siyuan Project”, sponsored by UNESCO and beautiful homesickness charity team.</li>
                          <li>Volunteer for Pujiang Innovation Forum, Shanghai Museum of Mature, Red Cross Association of Tongji Univ.</li>
                        </ul>
                      </div>

                      <div className='OtherContainer'>
                        <h3 className='other-title'>Experience</h3>
                        <ul>
                          <li>Apple Teacher</li>
                          <li>Microsoft Learn Student Ambassador</li>
                          <li>HUAWEI / OpenMMLab / DJI / Tencent / iFLYTEK, Campus Ambassador</li>
                        </ul>
                      </div>

                      <div className='OtherContainer'>
                        <h3 className='other-title'>Hobbies</h3>
                        <ul>
                          <li>🏊🏻 Swimming</li>
                          <li>🛻 Long-distance Driving</li>
                          <li>🚴🏼 Road Cycling</li>
                          <li>🗺️ Traveling</li>
                          <li>📸 Photography / 🛩️ Drone Photography</li>
                        </ul>
                      </div>

                    </div>
                </div>
            </Layout>

           
        )
    }
}

export default Research



export const pageQuery = graphql`
  query ResearchQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulSocialMedia (sort: { fields: [orderRank, createdAt], order: ASC }) {
        edges {
          node {
            fontclass
            color
            url
            title
          }
        }
      }
  }
`